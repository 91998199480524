import React, { useState, useEffect, useContext } from "react"
import { navigate, Link } from "gatsby"
import { useForm } from "react-hook-form"
import "twin.macro"
import Button from "../components/button"
import { useUser } from "../hooks/useUser"
import { useMe } from '../hooks/useMe'
import AccountLayout from '../components/accountLayout'
import UiStoreContext from "../contexts/uiStoreContext"
import { observer } from "mobx-react-lite"
import SEO from '../components/seo'
import { CognitoUser } from "../classes/user"

const emailValidator = require('email-validator')

const LogIn = observer(({location}) => {

	const [loginErr, setLoginErr] = useState(null)
	const email = location && location.state && location.state.email ? location.state.email : null;
	const { register, errors, handleSubmit } = useForm({defaultValues: {email}});
	const { user, refreshAuthenticatedUser, login } = useUser()
	const { refreshMe } = useMe()
	const uiStore = useContext(UiStoreContext)
	const [loading, setLoading] = useState(true)
	const [spin, setSpin] = useState(false)

	useEffect(() => {
		if (!uiStore.gate) {
			// console.log('login setting default gate location to /me')
			uiStore.setGate({
				postAuthLocation: '/me'
			})
		}
	}, [])

	const onSubmit = async (data) => {

		let { email, password } = data;

		try {
			setSpin(true)
			await login(email, password) 
			refreshMe()
		}
		catch(err) {
			switch(err.code) {
				case "UserNotFoundException":
				case "NotAuthorizedException":
					setLoginErr("Invalid username or password. Please try again.")
					break;
				case "UserNotConfirmedException":
					navigate("/verify", {state: {
						notConfirmed: true,
						email: email
					}});
					break;
				default:
					break;
			}
			console.log(err)
		}
		finally {
			setSpin(false)
		}
	}

	const loginForm = <>
		<SEO title="Log In"/>
		<AccountLayout onSubmit={handleSubmit(onSubmit)} message={<>To continue, log in to file.io.</>}>
			<label htmlFor="email">Email address</label>
			<input
				type="text"
				name="email"
				placeholder="Email"
				ref={register({
					required: true,
					validate: value => emailValidator.validate(value)
				})} />
			{errors.email && <div className="error">Please enter a valid email address</div>}
			<label htmlFor="password">Password</label>
			<input
				type="password"
				name="password"
				placeholder="Password"
				ref={register({
					required: true
				})} />
			{errors.password && <div className="error">Password is required</div>}
			<div tw="w-full mb-4 pl-1">
				<Link to="/forgot">Forgot your password?</Link>
			</div>
			{loginErr && <div className="error">{loginErr}</div>}
			<Button
				special={true}
				type="submit"
				tw="w-full"
				spin={spin}
			>Log In</Button>
			<div tw="text-center text-sm text-gray-500 mt-4">
				By using file.io, you agree to the <a href="/tos" target="top">Terms of Service</a> and <a href="/privacy" target="top">Privacy Policy</a>
			</div>
			<div tw="text-gray-500 mt-6">
				Don't have an account? <Link tw="no-underline border-b border-blue text-blue" to="/signup">Sign up</Link>.
			</div>
		</AccountLayout>
	</>
	
	const loadingScreen = <div>...</div>

	return loginForm

	// return loading ? loadingScreen : loginForm

})

export default LogIn
